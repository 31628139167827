<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A mixture of oxygen gas and hydrogen gas is prepared by electrolysis of
        <number-value :value="massWaterLiq" unit="\text{g}" />
        of liquid water. The mixture was collected over water at
        <number-value :value="temp" unit="^\circ\text{C,}" /> and
        <latex-number :number="Ptot.toFixed(1)" unit="\text{mmHg.}" />
        The volume of "wet" gas was found to be
        <number-value :value="volume" unit="\text{L.}" />
      </p>

      <p class="mb-2">a) How many moles of gas are in the mixture at the end of the reaction?</p>

      <calculation-input
        v-model="inputs.molTotal"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) How many moles of gas were produced by the reaction?</p>

      <calculation-input
        v-model="inputs.molFormed"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) What is the vapour pressure of water at
        <number-value :value="temp" unit="^\circ\text{C?}" />
        Express your answer to <b>3 significant figures</b>.
      </p>

      <calculation-input
        v-model="inputs.vapourPressure"
        class="mb-5"
        prepend-text="$\text{P:}$"
        append-text="$\text{mmHg}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) What is the mass percent of water in the gas phase? Express your answer to
        <b>3 significant figures</b>.
      </p>

      <calculation-input
        v-model="inputs.massPercent"
        prepend-text="$\text{Mass }\%:$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question454',
  components: {
    LatexNumber,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molTotal: null,
        molFormed: null,
        vapourPressure: null,
        massPercent: null,
      },
    };
  },
  computed: {
    massWaterLiq() {
      return this.taskState.getValueBySymbol('massWaterLiq').content;
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    vaporPressure() {
      if (this.temp.toFloat() >= 21.5 && this.temp.toFloat() < 22.5) {
        return 19.8;
      } else if (this.temp.toFloat() >= 25.5 && this.temp.toFloat() < 26.5) {
        return 25.2;
      } else if (this.temp.toFloat() >= 29.5 && this.temp.toFloat() < 30.5) {
        return 31.8;
      } else {
        return 39.9;
      }
    },
    nMolWaterGas() {
      return (
        (this.vaporPressure * this.volume.toFloat()) / 62.3636 / (this.temp.toFloat() + 273.15)
      );
    },
    nMolGenerated() {
      return (1.5 * this.massWaterLiq.toFloat()) / 18.02;
    },
    Ptot() {
      return (
        ((this.nMolWaterGas + this.nMolGenerated) * 62.3636 * (this.temp.toFloat() + 273.15)) /
        this.volume.toFloat()
      );
    },
  },
};
</script>
